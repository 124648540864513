import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import { GroupImage } from 'common/components/GroupImage';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';

import widgetSettingsParams from '../../../../settingsParams';

import { getDirection } from './helpers';

import styles from './GroupStripeItem.scss';

interface IGroupStripeItemSkeletonProps {
  even?: boolean;
}

export function GroupStripeItemSkeleton(props: IGroupStripeItemSkeletonProps) {
  const settings = useSettings();
  const imageLayout = settings.get(widgetSettingsParams.imageLayout);
  return (
    <Card
      gap="SP1"
      padding="SP0"
      height="240px"
      direction={getDirection(imageLayout, props.even)}
    >
      <div className={styles.image}>
        <GroupImage />
      </div>
      <CardContent verticalAlign="middle" width="100%">
        <Skeleton className={styles.title} />
        <Skeleton className={styles.info} width="50%" />
      </CardContent>
    </Card>
  );
}

GroupStripeItemSkeleton.displayName = 'GroupGridItemSkeleton';
